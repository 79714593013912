/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AudioAddManualResponseCommentDTO } from '../models/AudioAddManualResponseCommentDTO';
import type { ChangeManualResponseCommentRequest } from '../models/ChangeManualResponseCommentRequest';
import type { PhotoAddManualResponseCommentDTO } from '../models/PhotoAddManualResponseCommentDTO';
import type { SetTaskCheckingResultRequest } from '../models/SetTaskCheckingResultRequest';
import type { TaskCheckingResultStatusDTO } from '../models/TaskCheckingResultStatusDTO';
import type { TaskResultForCheckingResponse } from '../models/TaskResultForCheckingResponse';
import type { TextAddManualResponseCommentDTO } from '../models/TextAddManualResponseCommentDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaskCheckingService {

    /**
     * Получение списка задач для проверки.
     * @returns TaskResultForCheckingResponse Список задач для проверки
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcheckingGetlistforchecking({
        subjectIdsArray,
        examTypeIdsArray,
        studentIdsArray,
        curatorIdsArray,
        examStructTaskIdsArray,
        isForBank,
        isChecked,
        limit,
        offset,
    }: {
        subjectIdsArray?: Array<number>,
        examTypeIdsArray?: Array<number>,
        studentIdsArray?: Array<number>,
        curatorIdsArray?: Array<number>,
        examStructTaskIdsArray?: Array<number>,
        isForBank?: boolean | null,
        isChecked?: boolean | null,
        limit?: number,
        offset?: number,
    }): CancelablePromise<TaskResultForCheckingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks-checking/task-results',
            query: {
                'subjectIds[]': subjectIdsArray,
                'examTypeIds[]': examTypeIdsArray,
                'studentIds[]': studentIdsArray,
                'curatorIds[]': curatorIdsArray,
                'examStructTaskIds[]': examStructTaskIdsArray,
                'isForBank': isForBank,
                'isChecked': isChecked,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }

    /**
     * Получение задачи по идентификатору.
     * @returns TaskCheckingResultStatusDTO Задача
     * @throws ApiError
     */
    public static getAppApiEducationtaskTaskcheckingGetbyid({
        id,
    }: {
        /**
         * Идентификатор задачи
         */
        id: number,
    }): CancelablePromise<TaskCheckingResultStatusDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tasks-checking/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Задача с таким идентификатором не найдена`,
            },
        });
    }

    /**
     * Установить результат проверки задачи
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcheckingCheck({
        id,
        requestBody,
    }: {
        id: string,
        /**
         * Результат
         */
        requestBody?: SetTaskCheckingResultRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/task-results/{id}/check',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Добавить комментарий к тексту
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcheckingAddtextmanualresponsecomment({
        id,
        requestBody,
    }: {
        id: string,
        /**
         * Результат
         */
        requestBody?: TextAddManualResponseCommentDTO,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/task-results/{id}/text/comments',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Добавить комментарий к фото
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcheckingAddphotomanualresponsecomment({
        id,
        requestBody,
    }: {
        id: string,
        /**
         * Результат
         */
        requestBody?: PhotoAddManualResponseCommentDTO,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/task-results/{id}/photo/comments',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Удалить комментарий к ответу в задаче с ручной проверкой
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiEducationtaskTaskcheckingRemovemanualresponsecomment({
        id,
        commentId,
    }: {
        id: string,
        commentId: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/task-results/{id}/comments/{commentId}',
            path: {
                'id': id,
                'commentId': commentId,
            },
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Изменить комментарий к ответу в задаче с ручной проверкой
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiEducationtaskTaskcheckingChangemanualresponsecomment({
        id,
        commentId,
        requestBody,
    }: {
        id: string,
        commentId: string,
        /**
         * Результат
         */
        requestBody?: ChangeManualResponseCommentRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/task-results/{id}/comments/{commentId}',
            path: {
                'id': id,
                'commentId': commentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Добавить комментарий к аудио
     * @returns void
     * @throws ApiError
     */
    public static postAppApiEducationtaskTaskcheckingAddaudiomanualresponsecomment({
        id,
        requestBody,
    }: {
        id: string,
        /**
         * Результат
         */
        requestBody?: AudioAddManualResponseCommentDTO,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/task-results/{id}/audio/comments',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

}
