import {FC, memo, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {INavigationProps} from "./types";
import {styled} from "@mui/material/styles";
import {ListItemIcon, ListItemText, MenuItem, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import * as React from "react";
import NavItem from "../NavItem";
import MoreButton from "../MoreButton";
import {ReactComponent as IconAlso} from "./assets/icon__also.svg";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {RightsEnum} from "../../../generated/api";
import SchoolIcon from "@mui/icons-material/School";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import RuleIcon from "@mui/icons-material/Rule";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ScienceIcon from "@mui/icons-material/Science";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BallotIcon from "@mui/icons-material/Ballot";
import CalculateIcon from "@mui/icons-material/Calculate";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DatasetLinkedIcon from "@mui/icons-material/DatasetLinked";
import PeopleIcon from "@mui/icons-material/People";
import {Link, useLocation} from "react-router-dom";
import {ProfileContext} from "../../../App";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'transparent',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(19, 25, 32, 0.9)',
    },
    [`& .${tooltipClasses.tooltipPlacementRight}`]: {
        right: '-10px'
    },
}));

const Navigation: FC<INavigationProps> = (props) => {
    const MENU_LIMIT = 8
    const { isFull } = props
    const ref = useRef<HTMLDivElement | null>(null)
    const [morePositionLeft, setMorePositionLeft] = useState<number | undefined>(undefined)
    const location = useLocation()
    const profile = useContext(ProfileContext)

    const isAllowedCategory = useCallback((category: any): boolean => {
        if (!profile?.rights) return false;
        if (profile.rights.includes(RightsEnum.SUPERADMIN)) {
            return true;
        } else if (category.permission === RightsEnum.SUPERADMIN) {
            return false;
        }
        if (category.onlyForSchool && profile.cabinet?.type !== 'school') {
            return false;
        }
        if (profile.rights.includes(RightsEnum.ADMIN)) {
            return true;
        }
        return profile.rights.includes(category.permission);
    }, [profile])

    const categories = useMemo(() => [
        { link: '/webinars', text: "Расписание занятий", icon: <VideoLibraryIcon />, permission: RightsEnum.WEBINARS, onlyForSchool: false },
        { link: '/checking/tests', text: "Проверка работ", icon: <RuleIcon />, permission: RightsEnum.TEST_CHECKING, onlyForSchool: false },
        { link: '/lessons', text: "Библиотека уроков", icon: <ScienceIcon />, permission: RightsEnum.LESSONS, onlyForSchool: false },
        { link: '/tests', text: "Домашки и тесты", icon: <BallotIcon />, permission: RightsEnum.TESTS, onlyForSchool: false },
        { link: '/tasks', text: "Банк заданий", icon: <MenuBookIcon />, permission: RightsEnum.TASKS, onlyForSchool: false },
        { link: '/students', text: "Ученики", icon: <SchoolIcon />, permission: RightsEnum.STUDENTS, onlyForSchool: false },
        { link: '/programs', text: "Программы", icon: <CalendarMonthIcon />, permission: RightsEnum.PROGRAMS, onlyForSchool: true },
        { link: '/streams-education', text: "Потоки", icon: <ListAltIcon />, permission: RightsEnum.PROGRAMS, onlyForSchool: true },
        { link: '/subjects', text: "Предметы", icon: <CalculateIcon />, permission: RightsEnum.SUPERADMIN, onlyForSchool: false },
        { link: '/topics', text: "Темы", icon: <AccountTreeIcon />, permission: RightsEnum.SUPERADMIN, onlyForSchool: false },
        { link: '/exam-struct', text: "Структура экзаменов", icon: <ListAltIcon />, permission: RightsEnum.SUPERADMIN, onlyForSchool: false },
        { link: '/task-sources', text: "Источники заданий", icon: <DatasetLinkedIcon />, permission: RightsEnum.SUPERADMIN, onlyForSchool: false },
        { link: '/users', text: "Пользователи", icon: <PeopleIcon />, permission: RightsEnum.ADMIN, onlyForSchool: true }
    ].filter(isAllowedCategory), [isAllowedCategory]);

    useEffect(() => {
        if (ref.current) {
            const left = ref.current?.offsetWidth

            if (left) {
                setMorePositionLeft(left + 40 + 16 + 8)
            }
        }
    }, [])


    const activeIndex = useMemo(() => {
        return categories.findIndex((el) => {
            return location.pathname === el.link
        })
    }, [location.pathname])

    const moreText = useMemo(() => {
        if (isFull) {
            return 'Еще'
        }

        return undefined
    }, [isFull])


    return (
        <div ref={ref}>
            {categories.slice(0, MENU_LIMIT).map((el, key) => {
                const {icon} = el
                return (
                    <div
                        key={key}
                        onClick={() => {
                        }}
                    >
                        <BootstrapTooltip title={isFull ? undefined : el.text} placement={'right'}>
                            <div>
                                <NavItem
                                    Icon={icon}
                                    text={isFull ? el.text : undefined}
                                    href={el.link}
                                    selected={activeIndex === key}
                                />
                            </div>
                        </BootstrapTooltip>
                    </div>
                )
            })}
            {categories.length > MENU_LIMIT
                ? <MoreButton
                    totalElements={categories.length - MENU_LIMIT}
                    positionLeft={morePositionLeft}
                    content={(_, setOpen) => {
                        return (
                            <Paper style={{padding: '8px 0', minWidth: '208px', backgroundColor: '#263340', borderRadius: '16px'}}>
                                <MenuList style={{padding: 0}}>
                                    {categories.slice(MENU_LIMIT).map((el, key) => {
                                        const {icon} = el

                                        return (
                                            <Link key={key} to={el.link} style={{
                                                textDecoration: 'none',
                                                cursor: 'pointer'
                                            }}>
                                                <MenuItem
                                                    style={{
                                                        height: '48px'
                                                    }}
                                                    selected={activeIndex === key}
                                                    onClick={() => {
                                                        setOpen(null)
                                                    }}
                                                >
                                                    <ListItemIcon sx={{color: 'white'}}>
                                                        {icon}
                                                    </ListItemIcon>

                                                    <ListItemText style={{color: 'white'}}>
                                                        {el.text}
                                                    </ListItemText>
                                                </MenuItem>
                                            </Link>
                                        )
                                    })}
                                </MenuList>
                            </Paper>
                        )
                    }}>
                    <NavItem IconElement={IconAlso} text={moreText} />
                </MoreButton>
                : null}
        </div>
    )
}

export default memo(Navigation)