import * as React from "react";
import {redirect} from "react-router-dom";

const Tasks = React.lazy(() => import("./components/pages/tasks/TasksList"));
const Lessons = React.lazy(() => import("./components/pages/lessons/LessonsList"));
const Tests = React.lazy(() => import("./components/pages/tests/TestsList"));
const TestTasksList = React.lazy(() => import("./components/pages/tests/TestTasksList"));
const Sources = React.lazy(() => import("./components/pages/sources/SourcesList"));
const LessonsSubThemes = React.lazy(() => import("./components/pages/lessons/LessonsSubTopicsList"));
const LessonSubThemeTasksList = React.lazy(() => import("./components/pages/lessons/LessonSubTopicTasksList"));
const Programs = React.lazy(() => import("./components/pages/program/ProgramList"));
const ProgramCalendar = React.lazy(() => import("./components/pages/program/ProgramCalendar"));
const Students = React.lazy(() => import("./components/pages/students/StudentsList"));
const Subjects = React.lazy(() => import("./components/pages/subjects/SubjectsList"));
const ExamStructTasksList = React.lazy(() => import("./components/pages/examStructTasks/ExamStructTasksList"));
const Users = React.lazy(() => import("./components/pages/users/UsersList"));
const InWorkPage = React.lazy(() => import("./components/pages/InWorkPage"));
const Topics = React.lazy(() => import('./components/pages/topics/TopicsTree'));
const Webinars = React.lazy(() => import('./components/pages/webinars/WebinarsList'));
const StreamsEducation = React.lazy(() => import('./components/pages/streamEducation/StreamEducationList'));
const TestCheckingResults = React.lazy(() => import('./components/pages/testChecking/TestCheckingResultsList'));
const TestCheckingResultTasksList = React.lazy(() => import('./components/pages/testChecking/TestCheckingResultTasksList'));
const TaskCheckingResultsList = React.lazy(() => import('./components/pages/testChecking/TaskCheckingResultsList'));
const Onboarding = React.lazy(() => import('./components/pages/onboarding/Onboarding'));
const Settings = React.lazy(() => import('./components/pages/settings/Settings'));

export default [
  { path: "/", loader: () => redirect('/webinars') },
  { path: "tasks", element: <Tasks />, name: 'База заданий' },
  { path: "lessons", element: <Lessons />, name: 'Конструктор уроков' },
  { path: "lessons/:lessonId/sub-topics", element: <LessonsSubThemes />, name: 'Главы урока', parentPath: 'lessons'},
  { path: "lessons/:lessonId/sub-topics/:subTopicId/tasks", element: <LessonSubThemeTasksList />, name: 'Упражнения к главе урока', parentPath: 'lessons/:lessonId/sub-topics' },
  { path: "programs", element: <Programs />, name: 'Программы' },
  { path: "programs/:programId/calendar", element: <ProgramCalendar />, name: 'Календарь', parentPath: 'programs' },
  { path: "students", element: <Students />, name: 'Ученики' },
  { path: "tests", element: <Tests />, name: 'Домашки и тесты' },
  { path: "tests/:testId/tasks", element: <TestTasksList />, name: 'Задания теста', parentPath: 'tests' },
  { path: "subjects", element: <Subjects />, name: 'Предметы' },
  { path: "topics", element: <Topics />, name: 'Темы' },
  { path: "exam-struct", element: <ExamStructTasksList />, name: 'Структуры экзаменационных задач' },
  { path: "task-sources", element: <Sources />, name: 'Источники заданий' },
  { path: "users", element: <Users />, name: 'Пользователи' },
  { path: "webinars", element: <Webinars />, name: 'Вебинары' },
  { path: "streams-education", element: <StreamsEducation />, name: 'Потоки обучения' },
  { path: "checking/tests", element: <TestCheckingResults />, name: 'Проверка работ' },
  { path: "checking/tests/:resultId", element: <TestCheckingResultTasksList />, name: 'Список пройденных заданий', parentPath: 'checking/tests' },
  { path: "checking/tasks", element: <TaskCheckingResultsList />, name: 'Проверка работ' },
  { path: "onboarding", element: <Onboarding />, name: 'Регистрация преподавателя', withoutLayout: true },
  { path: "settings", element: <Settings />, name: 'Настройки' },
]